
import { ref, computed, onMounted, onBeforeUnmount, watch, provide, inject } from 'vue'
import schttp from 'public/src/services/schttp'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'
import { storeModuleName } from 'public/src/pages/product_app/store/modules/store_pages/constant.js'
import { useStore, useGlobalMapMutations } from './store.js'
import { useRouter } from './router.js'

const { language: commonLanguage = {}, langPath, GB_cssRight, } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export function createInjectionState(composable){
  const key = Symbol('InjectionState')
  const useProvidingState = (...args) => {
    const state = composable(...args)
    provide(key, state)
    return state
  }
  const useInjectedState = () => inject(key)
  return [useProvidingState, useInjectedState]
}
  
export const useStorePageMapGetters = (getterKeys) => {
  const store = useStore()
  return Object.fromEntries(
    getterKeys.map(getter => [
      getter,
      computed(() => store.getters[`${storeModuleName}/${getter}`])
    ])
  )
}

export const useStorePageData = ()=>{
  const { 
    storeData, storeAbtResult, language: storeLanguage, isFollowed
  } = useStorePageMapGetters(['storeData', 'storeAbtResult', 'language', 'isFollowed'])
  const { assignRootState } = useGlobalMapMutations(['assignRootState'])
  const { router, route, routerGoWish } = useRouter()
  const language = computed(()=>{
    return {
      ...commonLanguage,
      ...storeLanguage.value,
    }
  })

  const isTrendStore = computed(()=>{
    return storeData.value.showFollowTip === '1'
  })

  const storeCode = computed(()=>{
    return storeData.value.storeCode
  })


  return {
    storeCode,
    isFollowed,
    storeData,
    language,
    langPath,
    GB_cssRight,
    router,
    route,
    assignRootState,
    routerGoWish,
    storeAbtResult,
    isTrendStore,
  }
}

const storeHeaderCompose = (options)=>{
  const { route } = options || {}
  if(!route){
    console.error('=====isStorePage 需要传入route')
    return {}
  }
  const isStoreTopTabSticky = ref(false)
  const isBindEvent = ref(false)
  const cacheFetchStoreSearchDataParams = ref(null)

  const storeDefaultWords = ref([])

  const isStorePage = computed(()=>{
    return route.value.name === 'page_store'
  })

  watch(()=> isStorePage.value, (val)=>{
    if(val){
      fetchStoreSearchKeywords()
    }
  })

  const handleStoreTopTabSticky = (val)=>{
    isStoreTopTabSticky.value = val
  }
  const bindEventBus = ()=>{
    if(!window.appEventCenter) return
    if(isBindEvent.value) return
    isBindEvent.value = true
    window.appEventCenter.$on('store_pages:handleTabSticky', handleStoreTopTabSticky)
  }

  const offEventBus = ()=>{
    isBindEvent.value = false
    if(!window.appEventCenter) return
    window.appEventCenter.$off('store_pages:handleTabSticky', handleStoreTopTabSticky)
  }



  const fetchStoreSearchKeywords = async() =>{
    if(typeof window === 'undefined') return
    const store_code = getStoreCodeFromUrl()
    const params = {
      store_code,
      scene: 'store',
      word_type: '1'
    }
    const stringifyParams = JSON.stringify(params)
    if(cacheFetchStoreSearchDataParams.value === stringifyParams && storeDefaultWords.value.length > 0) return
    cacheFetchStoreSearchDataParams.value = stringifyParams
    try {
      const resData = await schttp({ 
        url: '/product/search/v3/get_keywords',
        params,
        useBffApi: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const { code, info } = resData || {}
      if(code !== '0'){
        throw resData
      }
      if(info && Array.isArray(info.keywords)){
        // eslint-disable-next-line require-atomic-updates
        storeDefaultWords.value = info.keywords
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('=====fetchStoreSearchKeywords error: ', error)
    }
  }



  onMounted(()=>{
    bindEventBus()
    if(isStorePage.value){
      fetchStoreSearchKeywords()
    }
  })
  onBeforeUnmount(()=>{
    offEventBus()
  })

  return {
    isStorePage,
    isStoreTopTabSticky,
    storeDefaultWords,
  }
}

const [useProvideStoreHeader, useInjectStoreHeader] = createInjectionState(storeHeaderCompose)

export {
  useProvideStoreHeader,
  useInjectStoreHeader,
}
